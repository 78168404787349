
<template lang="pug">
v-card(light, flat)
    v-row
        v-spacer
        v-col.text-center
            .text-h4 Get In Touch With Us
        v-spacer
    v-row
        v-col
            v-card(flat)
                v-row
                    v-col.text-center
                        v-card(flat)
                            v-card-text
                                v-icon(color='brand', size='64') location_on
                                br
                                span.font-weight-bold.brand--text Our Location
                                br
                                span {{ `${dorsett.address}` }}
                                br
                                span {{ dorsett.city }}, {{ dorsett.state }} {{ dorsett.zip }}
                    v-divider(vertical)
                    v-col.text-center
                        v-card(flat)
                            v-card-text
                                v-icon(color='brand', size='64') phone
                                br
                                span.font-weight-bold.brand--text Call Us
                                br
                                a.brand--text(:href='`tel:${dorsett.number}`', target='_blank') {{ dorsett.number }}
                    v-divider(vertical)
                    v-col.text-center
                        v-card(flat)
                            v-card-text
                                v-icon(color='brand', size='64') email
                                br
                                span.font-weight-bold.brand--text Email
                                br
                                a.brand--text(:href='`mailto:${dorsett.email}`', target='_blank') {{ dorsett.email }}
                    v-divider(vertical)
                    v-col.text-center
                        v-card(flat)
                            v-card-text
                                v-icon(color='brand', size='64') fax
                                br
                                span.font-weight-bold.brand--text Fax
                                br
                                span {{ dorsett.fax }}

    v-row
        v-spacer
        v-col
            .text-h5.text-center Let us know how we can help
        v-spacer
    v-row
        v-col
            v-card(flat)
                v-row
                    v-spacer
                    v-col.text-center.flex-shrink-1.flex-grow-0
                        v-card(width='300', height='300')
                            v-card-text
                                v-icon(color='brand', size='64') settings
                                br
                                .text-h6.brand--text Support
                                span.font-weight-bold Email
                                br
                                a.brand--text(:href='`mailto:${support.email}`', target='_blank') {{ support.email }}
                                br
                                span.font-italic Responses may take up to 24 hours.
                                br
                                br
                                span.font-weight-bold Phone
                                br
                                a.brand--text(:href='`tel:${dorsett.number}`', target='_blank') {{ dorsett.number }}
                                br
                                span.font-italic For emergency 24/7 support. 
                    v-col.text-center.flex-shrink-1.flex-grow-0
                        v-card(width='300', height='300')
                            v-card-text
                                v-icon(color='brand', size='64') real_estate_agent
                                br
                                .text-h6.brand--text Sales
                                span.font-weight-bold Email
                                br
                                a.brand--text(:href='`mailto:${sales.email}`', target='_blank') {{ sales.email }}
                                br
                                br
                                span.font-weight-bold Phone
                                br
                                a.brand--text(:href='`tel:${dorsett.number}`', target='_blank') {{ dorsett.number }}

                    v-col.text-center.flex-shrink-1.flex-grow-0
                        v-card(width='300', height='300')
                            v-card-text
                                v-icon(color='brand', size='64') school
                                br
                                .text-h6.brand--text Training
                                span.font-weight-bold Email
                                br
                                a.brand--text(:href='`mailto:${training.email}`', target='_blank') {{ training.email }}
                    v-spacer
</template>

<script>

export default {
    data () {
        return {
            dorsett: {
                name: 'Dorsett Controls',
                address: '486 N. Patterson Ave. Suite 301',
                city: 'Winston Salem',
                state: 'NC',
                zip: '27101-4264',
                number: '1-855-DT SCADA (387-2232)',
                email: 'info@dorsettcontrols.com',
                fax: '(336) 679-8811'
            },
            training: {
                email: 'training@dorsettcontrols.com'
            },
            support: {
                email: 'support@dorsettcontrols.com'
            },
            sales: {
                email: 'sales@dorsettcontrols.com'
            }
        };
    }
};
</script>
